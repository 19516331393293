.slider-img-div {
  height: 400px;
  margin-left: 5rem;
}

.slider-img-div>img {
  border-radius: 10px;
  object-fit: cover;
}

.slick-prev {
  left: 10px !important;
  z-index: 2;
}

.slick-next:before,
.slick-prev:before {
  color: #c4c4c4;
  font-size: 3rem;
  opacity: 1;
  transition: 200ms;
}

.slick-next {
  right: 35px !important;
  z-index: 2;
}

.sliderimage {
  height: 100%;
}

.slider-img-div {
  margin-left: 2rem;
  background: white;
}

.photo_heading {


  align-items: center;
  color: #009EE0;
  align-items: center;
  font-weight: 500;
  padding-top: 20px;
  margin: 15px;
  position: relative;
  margin-bottom: 1.5rem;
}


.photo_heading::before {

  content: '';
  position: absolute;
  bottom: -8px;
  left: 45%;
  width: 10%;
  height: 4px;
  background: #009EE0;
}

@media (max-width: 640px) {
  .slider-img-div {
    margin-left: 0rem;
    height: 260px;
  }

  .sliderimage {
    height: 92%;
    width: 100%;
  }

  .photo_heading {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}

.f-bold {
  font-weight: bold;
}

.f-2 {
  font-size: 2rem;
}

.f-2 {
  font-size: 2rem;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 992px) {
  .m-lg-auto {
    margin: auto !important;
  }

}

.flex {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
}

.gallery_photo {
  height: 350px;
  border-radius: 10px;
  width: 92%;
}