.content_img {

    border: 3px solid rgb(0, 118, 189);
    box-shadow: rgb(0 0 0 / 25%) 10px 8px 4px;
    width: 200px;
    height: 200px;
}
.content_img2 {

    border: 3px solid rgb(0, 118, 189);
    box-shadow: rgb(0 0 0 / 25%) 10px 8px 4px;
    width: 220px;
    height: 270px;
}

.messageHeading {
    font-size: 30px;
}

.alumni_head {

    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0.5rem !important;
}

.alumni {
    gap: 1.5rem !important;
    margin-bottom: 5px;
    text-align: justify;
}

.alumni_name {

    color: rgba(0, 0, 0, 0.61);
    font-size: 1.1rem;
    font-weight: 400;

}

.alumni_text {

    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    font-size: 15px;
    text-align: justify;
}

.alumni_text_container {
    margin-top: 0.5rem !important;
    text-align: justify;
    margin-left: 17px;
}

@media screen and (max-width: 767px) {
    .alumni {
        flex-direction: column;
        padding: 0px !important;
    }

    .messageHeading {
        font-size: 26px !important;
        justify-content: flex-start !important;
    }

    .alumni_head {
        text-align: left !important;
    }

    .alumni_img {
        display: flex;
        justify-content: center;
    }

    .alumni_text_container {
        margin-left: 0px !important;
    }
}