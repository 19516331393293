.apply-btn {
  text-align: center;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  font-weight: 500;
  color: white;
  background-color: #0076BD;
  border: 1px solid #0076BD;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.apply-btn:hover {
  background-color: white;
  color: #0076BD;
  border: 1px solid #0076BD;
}

.contentmaincont {
  display: flex;
  gap: 2rem;
  padding: 1rem 2rem;
  position: relative;
}

.home_main {
  width: 100vw;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 767px) {
  .contentmaincont {
    padding: 16px 20px !important;
    justify-content: flex-start;
    gap: 0;
  }
}