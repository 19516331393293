.progress-multistep {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .white-bg {
  height:100%;
  align-items: center;
  width:100%;
  display: flex;
  justify-content: center;
  border:1px solid var(--gray);
  border-radius:50%;
  font-size:12px;
  font-weight:600;
  }

  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background: white;
  }

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    /* border: 1px solid gray; */
    padding: 0.5rem 1rem;
    width: 300px;
    max-height: 62px;
    position: relative;
  }