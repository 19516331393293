* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {

    height: 100%;
}

body {


    display: flex;
    flex-direction: column;
    min-height: 100%;


}

.ll {

    padding: 20px;
}



.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    height: 100vh !important;
}



.nav-bar-main {

    position: fixed;
    width: 100%;
    min-height: 70px;
    left: 0;
    z-index: 9999;
    transition: .5s linear
}

.scrolled-nav {

    background-color: white;
    box-shadow: 1px 2px 6px grey
}

.nav-content {
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    width: 100vw
}

.nav-bar-main .logo1 {
    margin-left: 30px;

    height: 52px;
    object-fit: cover;


}

.nav-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.contact-button a {
    text-decoration: none !important;
    color: black !important;
}

.apply-btn {
    text-align: center;
    font-size: 1.1rem;
    padding: 0.4rem 2rem;
    font-weight: 500;
    color: white;
    background-color: #009EE0;
    border: 1px solid #009EE0;
    border-radius: 10px;
    transition: all 0.2s ease;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.apply-btn:hover {
    cursor: pointer;
    background: none;
    color: #009EE0;
}

.division-black {
    border-right: 2px solid black;
}

.division-black a {
    text-decoration: none !important;
    color: black !important;
}

.home_icon {
    background-color: #efefef;
    border: none;
    border-radius: 8px;
    width: 50px;
    height: 41px;
    padding: 0.5rem;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.three-lines {
    background-color: white;
    border-radius: 7px;
    padding: 0.2rem;
}

.radius-6 {
    border-radius: 6px;
    padding: 0.2rem;
}

.nav-dropdwom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
}


.navbar_menu_main {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgb(0 0 0 / 70%), rgb(0 0 0 / 70%));
    z-index: 100000;
    cursor: pointer;
    animation: nav-fade-in 0.2s linear alternate;
}

.nav_cont {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    width: 100vw;
}

.cross-div {
    background-color: white;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 30px;
    height: 30px;
}


.menu_main {
    display: flex;
    justify-content: center;
    width: 60vw;
    margin: auto;
}

.menu_item-1 {
    padding: 0rem 2rem;
    line-height: 3rem;
    flex: 1 1;
    margin-top: -55px;
}

.drop {

    line-height: 2.4rem;
    color: white;
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 0.5rem !important;
}

/* .header{
    background: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px 60px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    position: sticky;
    top: 0%;
    left: 0;
    background: #F8F9F9 ;
} */

/* .navbar-list{
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.header .logo{
    margin-top: 10px;
    padding-left: 10px;
  
}


 .logo{
    height: 52px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    justify-content: space-between;
}


.navbae-list {

    display: flex;
    padding-right: 20px;
}



.navbar-list ul{

    padding: 10px 6px;
}

.navbar-list  a{

    text-decoration: none;
}
.navbar-list .button-1 {
  background-color: #009EE0;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;

  height: 50px;
  margin-top: 6px;
  list-style: none;

  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 170px;
  font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav{

    background:#F8F9F9 ;
}

.navbar-list .btn1{

    width: 46px;
    margin-top: 5px;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-block;
} */

/* 
///////course section///// */
.courseoffer {

    margin-top: 10px;
}

.courseoffer .container {
    margin-top: 10x;

    text-align: center;
}

.courseoffer .container h1 {

    align-items: center;
    color: #009EE0;
    align-items: center;
    font-weight: 700;
    padding-top: 20px;
    margin: 15px;
    position: relative;
}

.sos_para1 {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.61);
    text-align: center;
    line-height: 29px
}

.courseoffer .container h1::before {


    content: '';
    position: absolute;
    bottom: -20px;
    left: 43%;
    width: 15%;
    height: 4px;
    background: #009EE0;
}

.courseoffer .container .desc {

    position: relative;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1px;
    color: black;
    width: 60%;
    left: 18%;
    text-align: center;
}

.courseoffer .container .desc3 {

    position: relative;

    font-style: normal;
    /* font-weight: 500; */
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 1px;
    color: black;
    width: 90%;
    left: 5%;
    text-align: center;
}

.courseoffer .container .desc2 {

    position: relative;
    font-style: normal;
    text-align: justify;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
}

/* /////////// cards css //////////// */
.coursecard {

    background: #F8F9F9;
}

.coursecard h2 {

    text-transform: capitalize;
    align-items: center;
    color: black;
    align-items: center;
    font-weight: 700;
    padding-top: 20px;
    margin: 15px;
    margin-bottom: 60px;
    position: relative;

}

.cc {

    padding: 9px;
    width: 100%;
    text-align: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

}

.coursecard .row {

    display: flex;
    justify-content: center;
    align-items: center;
}

.coursecard .row .card .card-body {
    /* 
    margin-bottom: 30px; */
}

.coursecard .row .card .card-img-top {

    width: 100%;
    height: 31vh;
    object-fit: cover;
}

.freeStructure {
    text-decoration: none;
    color: #009EE0;
}

.cardMargin {
    margin-top: 60px;
}

@media(max-width: 400px) {
    .ncontact .conhead {

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

}

.myheading {

    margin-top: 2rem;
    text-align: center;



    font-weight: 700;
    font-size: 40px;


}

@media (max-width: 600px) {
    .coursecard .row .card .card-img-top {

        width: 100%;
        height: 20vh;
        object-fit: cover;
    }

    .ncontact .conhead {

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
    }

    .d-flex {

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #btnn {
        display: block;
        margin-left: 115px;

    }

    #conimg {

        width: 20rem;
    }

    .myheading {

        font-size: 30px;
        margin-left: 0;
    }




}

@media (max-width:974px) {
    #aluhead {

        font-size: 25px;
        margin-top: 10px;

    }

    #banner_sec {
        height: 100%;
    }

    #aluhead2 {
        color: #27968E;
        font-weight: 500;
        font-size: 15px;
        margin-top: 1rem !important;
    }

    #ibtn {


        margin-top: 0;

    }

    #read-more {
        background-color: #101E3F;
        padding: 0.5rem 1rem;
        border-radius: 25px;
        color: white;
        font-weight: 200;
        font-size: 1.1rem;
    }

    .ccc::before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 38%;
        width: 26%;
        height: 4px;
        background: #009EE0;
    }
}

.coursecard .row .card:hover {
    box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px;
    ;
}

.be .info {





    position: relative;
    border-radius: 40px;
    border: 1px solid #009EE0;
    padding: 10px;
    outline: none;
    color: #009EE0;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    background-color: transparent,


}

.be .info:hover,
.info:focus {

    cursor: pointer;
    background: transparent;
    /* color: black;
    border: 2px solid #000080; */
    text-decoration: none;
    box-shadow: 0 0.5em 0.5em -0.4em #009EE0;
    transform: translateY(-0.25em);
    ;


}

.coursecard .row {

    padding-left: 6rem;

}

.coursecard .be {

    border: none;
}

@media only screen and (max-width: 600px) {
    .coursecard .row {

        padding-left: 0;
        margin-left: 10%;

    }

}



.coursecard .row .card {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90% !important;
    margin-bottom: 30px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.87);
    background: #EBF4FA;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


    overflow: hidden;
}

.ccard {

    text-align: center;

}


/* ////// placement////// */
.placement h2 {
    text-transform: capitalize;
    align-items: center;
    color: #009EE0;
    align-items: center;
    font-weight: 700;
    padding-top: 20px;
    margin: 15px;
    position: relative;


}

.placement h1::before {

    content: '';
    position: absolute;
    bottom: -15px;
    left: 43%;
    width: 14%;
    height: 4px;
    background: #009EE0;
}

.placement {
    margin-top: 30px;


}

.sliding {

    height: 250px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.placement .slide-track {

    display: flex;
    margin-top: 20px;
    width: calc(250px *10);
    animation: scroll 40s linear infinite;

}

@keyframes scroll {

    0% {
        transform: translateX(0);
    }

    100% {

        transform: translateX(calc(-250px*5));
    }
}

.placement .slide-track .slide {

    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
    overflow: hidden;
}

.placement .slide-track .slide img {
    max-width: 100%;

    object-fit: cover;

    transition: transform 1s;

}

.placement .slide-track .slide img:hover {

    transform: translateZ(20px);
}



/* ///contact// */

.ncontact {
    background: #FFFFFF;
}


.ncontact .firstsection {


    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1rem 156px 2rem 127px;
}

@media (max-width:1311px) {

    .ncontact .firstsection {


        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-left: 45px;
        margin-right: 43px;
    }


}

@media only screen and (max-width:1127px) {
    .ncontact .firstsection {


        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        margin: 10px;
        width: 100%;
    }
}

.ncontact {
    padding: 15px;
}

.ncontact .conhead {
    text-transform: capitalize;
    margin-bottom: 2rem;
    color: black;

    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    position: relative;


}

.ccc {
    text-transform: capitalize;
    margin-bottom: 2rem;
    color: #009EE0;

    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    position: relative;
    margin-top: 10px;

}

.ccc::before {

    content: '';
    position: absolute;
    bottom: -10px;
    left: 47%;
    width: 6%;
    height: 4px;
    background: #009EE0;


}

.ncontact .firstsection .name {

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.ncontact .firstsection .contact-input {

    background: #f5f5f5;
    border-radius: 10px;
    height: 60px;
    width: 250px;
    padding-right: 40px;
}

.ncontact .firstsection .name img {

    opacity: .4;
}

.input-field {

    background: #f5f5f5;
    border-radius: 10px;
    height: 40px;
    padding-right: 9px;
    width: 150px;
    padding-left: 0.5rem;
    border-style: none;


}

.ncontact .firstsection .secondinput {

    margin-left: 2rem;

    margin-right: 2rem;

}

.form-control {

    border-style: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.for-btn {
    background: #009EE0;
    border: 0;
    border-radius: 13px;
    box-shadow: 0 0 1px transparent;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 22px;
    padding: 0.6rem 1rem;
    text-transform: capitalize;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    transition-property: color;
    width: 30%
}

.btnn {

    display: block;
    margin-left: 169px;
    margin-right: auto;
}

.btnn:hover,
.btnn:focus {



    box-shadow: 0 0.5em 0.5em -0.4em #009EE0;
    transform: translateY(-0.25em);



}

.ncontact .secondsection {

    width: 25rem;
    border-radius: 0.25rem;
}

.avtar {

    display: inline-flex;
    position: relative;
    padding-bottom: 45px;
}

.avtar img {

    height: 100%;
    object-fit: cover;
    width: 100%
}

.form-control {
    border: none;
}




/* //////// Alumni ////////////// */

.alumni {

    padding-left: 1rem;
    padding-right: 1rem;
    margin: 40px 0;
}



.banner {


    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: url("../../../public/images/banner3.png");
    width: 100%;

    height: 50vh;
    background-position: center;


    background-size: 100% 100%;
    object-fit: cover;

    background-repeat: no-repeat;


}

.banner p {

    color: #101E3F;
    font-weight: bold;
    font-size: 60px;
}

.banner h2 {

    color: #27968E;
    font-weight: 500;
    font-size: 30px;
    margin-top: 0.5rem !important;
}

.alubtn {


    padding: 15px 30px;

    border-radius: 40px;
    padding: 15px 30px;
    border: none;
    outline: none;
    color: #fff;
    background: #000080;
    font-size: 15px;
    font-weight: 600;
    border-radius: 40px;

    text-decoration: none;



}

.read-more {

    background-color: #101E3F;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
}

/* .alumni .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */


.inspired_button {
    margin-bottom: 0rem;
    margin-top: 2.5rem;
}

.inspired_button a {
    text-decoration: none !important;
    color: black !important;
}

.alubtn:hover,
.textbt:hover,
.alubtn:focus,
.textbt:focus {
    cursor: pointer;
    background: transparent;
    color: black;
    border: 2px solid #000080;
    text-decoration: none;
    box-shadow: 0 0.5em 0.5em -0.4em #000080;
    transform: translateY(-0.30em);

}




@media only screen and (max-width: 1078px) {

    body {

        width: 100%;
    }


}