.cover-img{
    width:100vw;
    height:95vh;
  
}
@media(max-width:640px){

    .cover-img{
       
        height:50vh
    }

}