.institue {
    margin-left: 80px;
    margin-right: 80px;

}

.firstinstitute {

    display: flex;
    justify-content: flex-start;
}

@media (min-width: 1024px) {
    .lg\:flex-row {
        flex-direction: row;
    }
}

@media (max-width: 800px) {
    .flex-col {
        flex-direction: column;
        margin-top: 10px;
        text-align: center;
        justify-content: center;
    }

    .firstinstitute .aboutimg {
        width: 80vw !important;
        height: 280px;
        border-radius: 0.5rem;
    }
}



.firstinstitute .aboutimg {
    width: 25vw;
    height: 280px;
    border-radius: 0.5rem;
}

.shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.institue_heading {

    align-items: center;
    color: #009EE0;
    align-items: center;
    font-weight: 700;
    padding-top: 11px;
    margin: 15px;
    position: relative;
    margin-bottom: 1.5rem;
}


.institue_heading ::before {

    content: '';
    position: absolute;
    bottom: -11px;
    left: 23%;
    width: 55%;
    height: 4px;
    background: #009EE0;
}

.about_sos {
    font-weight: 600;
    font-size: 28px;
    display: flex;
    align-items: center;
    color: #009EE0;
}

.sos_para {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.61);
    text-align: justify;
    line-height: 29px
}

@media screen and (max-width: 767px) {
    .institue {
        margin-left: 20px;
        margin-right: 20px;
    }

    .institue_heading {
        margin: 0px !important;
    }

    .institue_heading h1 {
        margin: 0px !important;
    }

    .firstinstitute .aboutimg {
        width: 100% !important;
        height: 280px;
        border-radius: 0.5rem;
    }

    .about_sos {
        padding: 10px 0;
    }
}