.content-img{

    width: 200px;
    height: 200px;
    border: 1px solid  rgba(0, 0, 0, 0.61);
    box-shadow: rgb(0 0 0 / 25%) 10px 8px 4px;
}
h1{

    align-items: center;
    color: #009EE0;
    align-items: center;
    font-weight: 700;
    padding-top: 20px;
    margin: 15px;
    position: relative;
    
}

.alumni-name{
    
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.alumni-text{

    color: rgba(0, 0, 0, 0.61);
    font-weight: 500;
    text-align: justify;
}

.ouralum{

    

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.mandatory_headingalum{
    font-size: 34px;
    font-weight: 700;
    color: #009EE0;
    margin-left: 2rem;
    
}
.ouralum:nth-child(3)
{
    margin-top: 35px;
    
}
.ouralum:nth-child(2)
{
    margin-top: 10px;
    
}

.ouralum:nth-child(4)
{
    margin-top: 35px;
    margin-bottom: 40px;

}