* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html{

    height: 100%;
}

body {

    
    display: flex;
    flex-direction: column;
    min-height: 100%;
 

}






.banner1{

    width: 100%;
    height: 98vh;
    max-width: 100%;
    left: 0;
    top: 0;
   
}



 .box{
    background: #009EE0;

    height: 70px;
    width: fit-content;
    border-radius: 10px;
 

}

@media (max-width:673px) {
    .box{
   
        position: absolute;
        bottom: 100px;
        margin-right: 0;
        font-size: 18px;
        color: white;
    }
    
    
}

@media (max-width:701px) {

    .box{



        align-items: center;
        display: flex;
        justify-content: center;
    
    }
    
}


 .h{
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 24px;
    word-spacing: 2px;
    width: auto;
    font-family: Montserrat;
    line-height: 29px;
    



}
@media only screen and (max-width: 600px) {
    .image-slider .box{
        background: #009EE0;
    
        height: 70px;
        width: 80%;
    
     
    
    }
    
  }

.bottomleft{
   
    position: absolute;
    bottom: 100px;
    left:  4%;
    font-size: 18px;
    color: white;
}



.home img{

    position: absolute;
    width: 100%;
    height: 120%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    animation: zoom-in 3s linear infinite;
}

@media (max-width:670px)
{
    .bottomleft{
        
        left: 0%;
    }

    .h{

        font-size: 20px;
        
    }
}
/* 
.banner1{

    animation: slide1 12s linear infinite;
}
.banner2{

    animation: slide2 12s linear infinite;
}
.banner3{

    animation: slide3 12s linear infinite;
}
.banner4{

    animation: slide4 12s linear infinite;
}
.banner5{

    animation: slide5 12s linear infinite;
}
.banner6{

    animation: slide6 12s linear infinite;
}
.banner7{

    animation: slide7 12s linear infinite;
}
.banner8{

    animation: slide8 12s linear infinite;
}

@keyframes slide1{

    0%{
        visibility: visible;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: visible;
    }
}
@keyframes slide2{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: visible;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide3{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: visible;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide4{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: visible;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide5{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: visible;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide6{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: visible;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide7{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: visible;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide8{

    0%{
        visibility: hidden;
    }
    18%{

        visibility: hidden;
    }
    30%{
        visibility: hidden;
    }
    45%{
        visibility: hidden;
    }
    60%{
        visibility: hidden;
    }

    75%{
        visibility: hidden;
    }
    90%{
        visibility: hidden;
    }
    100%{
        visibility: visible;
    }
} */

/* @keyframes slide2{

    0%{
        visibility: hidden;
    }
    25%{
        visibility: hidden;
    }
    50%{

        visibility: visible;
    }
    75%{
        visibility: hidden;
    }
    100%{
        visibility: hidden;
    }
}
@keyframes slide3{

    0%{
        visibility: hidden;
    }
    25%{
        visibility: hidden;
    }
    50%{
        visibility: hidden;
    }
    75%{
        visibility: visible;
    }
    0%{
        visibility: hidden;
    }
}
@keyframes slide4{

    0%{
        visibility: hidden;
    }
    25%{
        visibility: hidden;
    }
    50%{
        visibility: hidden;
    }
    75%{
        visibility: hidden;
    }
    100%{
        visibility: visible;
    }
} */


/* .image-slider{
  
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 95vh;
    background-image: url("../../../public/images/img11.png");
    background-size: 100% 100%;
    object-fit: cover !important;
    animation: changeImage 20s linear infinite ;
   

} */

/* @keyframes changeImage {



    0%{

        background-image: url("../../../public/images/img11.png");
    }
    25%{

        background-image: url("../../../public/images/img11.png");
    }
    50%{

        background-image: url("../../../public/images/img11.png");
    }
  
    
} */


.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  